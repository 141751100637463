<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <b-row class="mt-2">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="4">Datos Generales</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 25%">Nombre: </b-th>
                                <b-td colspan="3">{{ service.name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Descripción: </b-th>
                                <b-td colspan="3">{{ service.description }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 25%">Categoría: </b-th>
                                <b-td style="width: 25%" v-if="service.entityType !== ''">{{ (entityTypes.find(item => item.code === service.entityType)).label }}</b-td>
                                <b-td style="width: 25%" v-else></b-td>
                                <b-th style="width: 25%">Tipo de servicio: </b-th>
                                <b-td style="width: 25%">
                                    <template v-if="service.isCollective && service.isExclusive">Colectivo / Exclusivo</template>
                                    <template v-else-if="service.isCollective">Colectivo</template>
                                    <template v-else-if="service.isExclusive">Exclusivo</template>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Duración: </b-th>
                                <b-td v-if="service.type !== ''">{{ service.duration }} día{{ service.duration === 1 ? '' : 's' }}</b-td>
                                <b-th>Horario: </b-th>
                                <b-td>
                                    <template v-if="service.durationType === 'ALL'">Todo el dia</template>
                                    <template v-else-if="service.durationType === 'SPECIFIC'">
                                        {{ service.startTime.split(':').slice(0,2).join(':') }} - {{ service.endTime.split(':').slice(0,2).join(':') }}
                                    </template>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col md="6">
                    <template v-if="service.isCollective">
                        <b-table-simple bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th colspan="2">Tarifa por pasajero</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width: 50%; text-align: right">Adultos</b-th>
                                    <b-th style="width: 50%; text-align: right">Niños</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td style="text-align: right">$ {{ formatCurrency(service.basePriceList.adultFare) }}</b-td>
                                    <b-td style="text-align: right">$ {{ formatCurrency(service.basePriceList.childrenFare) }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </template>
                    <template v-if="service.isExclusive">
                        <b-table-simple bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th colspan="2">Tarifa por servicio exclusivo</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td style="text-align: right">$ {{ formatCurrency(service.basePriceList.serviceFare) }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </template>
                </b-col>
                <b-col md="6">
                    <b-table-simple bordered>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="3">Gastos</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>Nombre</b-th>
                                <b-th>Tipo</b-th>
                                <b-th style="width: 20%; text-align: right">Costo Unitario</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>Entrada Adulto</b-td>
                                <b-td>Por pasajero</b-td>
                                <b-td style="text-align: right">$ {{ formatCurrency(service.defaultExpenseList.adultFare) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>Entrada Niño</b-td>
                                <b-td>Por pasajero</b-td>
                                <b-td style="text-align: right">$ {{ formatCurrency(service.defaultExpenseList.childrenFare) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>Casetas</b-td>
                                <b-td>Por servicio</b-td>
                                <b-td style="text-align: right">$ {{ formatCurrency(service.defaultExpenseList.standFare) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>Gasolina</b-td>
                                <b-td>Por servicio</b-td>
                                <b-td style="text-align: right">$ {{ formatCurrency(service.defaultExpenseList.gasolineFare) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>Desayunos</b-td>
                                <b-td>Por pasajero</b-td>
                                <b-td style="text-align: right">$ {{ formatCurrency(service.defaultExpenseList.breakfastFare) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>Viaticos</b-td>
                                <b-td>Por servicio</b-td>
                                <b-td style="text-align: right">$ {{ formatCurrency(service.defaultExpenseList.travelExpensesFare) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'ServiceIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'ServiceEdit', params: { id: service.id }}" v-if="service.id">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { serviceUrl } from '@routes';
import * as constants from '@constants';
import { formatCurrency } from '../../js/utilities';
import { formatTime } from '../../js/utilities';
import { PriceList, generateBasePriceList } from '../../models/priceList';

export default {
    data() {
        return {
            service: {
                id: 0,
                name: '',
                description: '',
                isCollective: true,
                isExclusive: true,
                duration: 0,
                basePriceList: generateBasePriceList(),
                entityType: '',
                defaultExpenseList: ''
            },
            breadcrumb: {
                title: 'Servicios',
                path: [
                    {
                        name: 'ServiceIndex',
                        text: 'Servicios'
                    }
                ]
            },
            isLoading: false,
            entityTypes: [
                { code: 'TOUR', label: 'Tour' },
                { code: 'TRANSFER', label: 'Traslado' }
            ]
        };
    },
    created() {
        this.service.id = this.$route.params.id ?? 0;

        this.breadcrumb.path.push({
            name: 'ServiceShow',
            text: 'Detalle'
        });

        this.$emit('update-breadcrumb', this.breadcrumb);

        this.loadData();
    },
    methods: {
        formatCurrency: formatCurrency,
        loadData() {
            this.isLoading = true;

            this.axios.get(serviceUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.service = response.data.service;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        formatTime: formatTime
    },
    computed: {
    }
};
</script>

<style scoped>

</style>